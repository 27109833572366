/* eslint-disable @nx/enforce-module-boundaries */
import { Box, Flex, HStack, Square, Stack, VStack } from '@mybridge/ui/layout';
import { Link } from '@mybridge/ui/link';
// import Link from 'next/link';
import { SeeMore } from '@mybridge/ui/see-more';
import { Skeleton } from '@mybridge/ui/skeleton';
import { Spinner } from '@mybridge/ui/spinner';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { PostBoxContext, usePostBoxContext } from '../context';
import { MediaDisplay } from '../media-display';
import { PostBoxHeader } from '../header';
import {
  Heading,
  Text,
  Image,
  Button,
  Card,
  Avatar,
  Divider,
  IconButton,
  useToast,
} from '@mybridge/ui';
import { ForceSignInWrapper } from 'v4/components/force-sign-in-wrapper';
import { useRouter } from 'next/router';
import {
  BookMarkedPostIcon,
  BookMarkPostIcon,
  EventCalendarLineIcon,
  EventClockIcon,
  EventHostIcon,
  LinkLineIcon,
  LocationLineIcon,
  Upload,
} from '@mybridge/icons';
import moment from 'moment';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';
import _ from 'lodash';
import { CALL_TO_ACTION } from 'v4/lib/data';
import Slider from 'react-slick';
import { AdCampaignDefaultImageIcon } from '@mybridge/icons/CampaignIcon';
import SinglePostBody from '../single-post-body';
import { isDesktop, isMobile } from 'react-device-detect';
import { UserDetailsPopover } from 'v4/components/user-details-popover';
import classNames from 'classnames';
import { EventPostBody } from '../event-body';
import { blogDetailsSlug, bookMarkPost } from 'v4/store/slices/blogSlice';
import { useDispatch } from 'react-redux';

export const PostBoxBody = ({ isOnlyPreview, isSearch,fromArticle=false,fromPublicEvent=false,openedInPopup=false,fromSeperateList=false,fromIndex=false,...props }) => {
  const { push } = useRouter();
  const targetRef = useRef(null);
  const toast = useToast();
  const {
    post,
    hasInsights,
    isInFrame,
    showMedia = true,
    postModalLink,
    hasMedia,
    userProfileInfo,
    handleAdClicksRouting,
  } = useContext(PostBoxContext);
  const {
    id,
    content,
    repost,
    type,
    title,
    author,
    company,
    excerpt,
    readers,
    slug,
    media,
    start_time,
    end_time,
    attendees,
    ad_format_detail,
    ad_format,
    description,
    child_posts,
  } = post || {};
  const repostBoxContext = usePostBoxContext({ post: repost });
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isBookMarked, setIsBookMarked] = useState(false);
  const dispatch = useDispatch();

  const quoted = repost && (content?.length || hasMedia);
  const isArticle = type === 3;
  // if (isArticle) {
  //   console.log(post);
  // }

  const isEvent = type === 2;
  const isAdPost = type === 4;
  const isProfilePicPost =
    post?.description === 'USER_PROFILE_PIC_UPDATE' ||
    post?.description === 'COMPANY_PROFILE_PIC_UPDATE';
  const isProfileBannerPost =
    post?.description === 'USER_PROFILE_BANNER_PIC_UPDATE' ||
    post?.description === 'COMPANY_PROFILE_BANNER_PIC_UPDATE';

  const settings = {
    dots: false,
    className: 'center1',
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // infinite: true,
          // dots: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
    // arrows: ad_format_detail?.media?.length > 3 ? true : false,
  };

  useEffect(() => {
    if (post?.bookmark_user_ids?.includes(userProfileInfo?.id)) {
      setIsBookMarked(true);
    } else {
      setIsBookMarked(false);
    }
  }, [post]);

  const handleBlogBookMark = (blogDetails) => {
    dispatch(bookMarkPost(blogDetails)).then((res) => {
      if (res?.payload?.readers?.length > 0) {
        toast({
          status: 'success',
          position: 'top',
          description: 'Article bookmarked!',
        });
      }
      dispatch(blogDetailsSlug(blogDetails));
    });
  };

  useEffect(() => {
    if (isAdPost) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (!isOnlyPreview) {
            setIsVisible(entry.isIntersecting);
            handleAdClicksRouting('impression', id);
            handleAdClicksRouting('reach', id);
          }
        },
        {
          root: null, // viewport
          rootMargin: '0px', // no margin
          threshold: 0.2, // 50% of target visible
        }
      );

      if (targetRef.current) {
        observer.observe(targetRef.current);
      }

      // Clean up the observer
      return () => {
        if (targetRef.current) {
          observer.unobserve(targetRef.current);
        }
      };
    }
  }, []);

  // if (isEvent) {
  // console.log('post', post);
  // }

  // console.log(
  //   'postData',
  //   ad_format_detail?.video
  // );
  const userFullName = useMemo(() => getUserFullName(author), [author]);
  const userProfilePic = useMemo(() => getUserProfilePic(author), [author]);

  const link = company?.name?.length
    ? `/c/${company?.slug}`
    : `/p/${author?.slug}`;

  const isCompnyProfilepicture =
    company?.banner_image &&
    !company?.banner_image?.includes('default_banner_image.png')
      ? company?.banner_image
      : '';

  const isPersonalProfilepicture =
    author?.profile_banner_pic &&
    !author?.profile_banner_pic.includes('banner-placeholder1.png')
      ? author?.profile_banner_pic
      : '';
console.log(fromIndex,"fromIndexfromIndex");
  return (
    <>
      {isProfilePicPost ? (
        <>
          {isProfilePicPost && (
            <Box
              // px={!isEvent && ['15px', '30px', '60px']}
              pl={!isEvent && ['15px', '30px', '60px']}
              pr={!isEvent && ['15px', '30px', '60px']}
              className={classNames(child_posts?.length ? 'postHasChild' : '')}
            >
              <Text
                color="brandGray.700"
                // px={4}
                pb={(showMedia && hasMedia) || !hasInsights ? 4 : 0}
                dangerouslySetInnerHTML={{
                  __html: content
                    ?.replace?.('\r', '<br/>')
                    ?.replace('\n', '<br/>'),
                }}
                fontSize="md"
                w="100%"
                textAlign="left"
              />
              <HStack
                w="100%"
                justifyContent="center"
                position="relative"
                zIndex="0"
                // px={['30px', '30px', '60px']}
              >
                <Link overflow="hidden" href={`/post/${id}`}>
                  <Box
                    bgImage={
                      company?.name?.length
                        ? isCompnyProfilepicture
                        : isPersonalProfilepicture
                    }
                    bgPosition="center"
                    bgSize="cover"
                    position="absolute"
                    margin={['0px', '0px']}
                    borderRadius="10px"
                    left="0"
                    top="0"
                    right="0"
                    height="200px"
                  ></Box>
                  <Box
                    w={['200px', '300px']}
                    h={['200px', '300px']}
                    borderRadius="full"
                    border="2px solid #fff"
                    overflow="hidden"
                    position="relative"
                    zIndex="2"
                    mt={
                      company?.name?.length
                        ? isCompnyProfilepicture === ''
                          ? ''
                          : ['100px', '100px']
                        : isPersonalProfilepicture === ''
                        ? ''
                        : ['100px', '100px']
                    }
                    mb={
                      company?.name?.length
                        ? isCompnyProfilepicture === ''
                          ? ''
                          : 8
                        : isPersonalProfilepicture === ''
                        ? ''
                        : 8
                    }
                  >
                    <Image
                      src={media?.[0]?.file}
                      width={isMobile ? 200 : 300}
                      height={isMobile ? 200 : 300}
                      alt={isProfilePicPost}
                    />
                  </Box>
                </Link>
              </HStack>
            </Box>
          )}
        </>
      ) : (
        <>
         <Box
            w={openedInPopup?'auto':"100%"}
            px={!isEvent &&post?.repost?.share_count>0&&!quoted&&!fromIndex&&!fromPublicEvent? ['15px', '30px', '0px']:isEvent&&!fromIndex&&!post?.repost?.share_count<=0&&quoted&&!fromPublicEvent?['15px', '30px', '60px']:fromPublicEvent&&!fromIndex?['0px']:fromIndex?'0px':isEvent&&!fromPublicEvent?'0px':['15px', '30px', '60px']}
            pl={!isEvent&&!fromArticle&&!isArticle&&post?.repost?.share_count>0&&!isMobile&&!quoted&&!fromIndex ?"30px":!isEvent&&!fromArticle&&!isArticle&&post?.repost?.share_count>0&&isMobile&&quoted&&!fromIndex?"20px": !isEvent&&!fromArticle&&!isArticle&&!post?.repost?.share_count&&!fromIndex?"60px":isMobile&&!fromPublicEvent?"10px":"0px"}
            pr={!isEvent&&fromPublicEvent?['15px', '30px', '210px']:!isEvent&&!fromPublicEvent&&isDesktop?['15px', '30px', '30px']:['15px', '30px', '0px']}
            className={classNames(child_posts?.length ? 'postHasChild' : '')}
          >
            {content?.length ? (
              <Link
              href={`/post/${id}`}
              scroll={false} // Prevents scroll/re-render of the parent page
              onClick={(e) => {
                e.preventDefault();
                localStorage?.setItem('commentCLicked', true);
                push(`/post/${id}`, undefined, { 
                  shallow: true,
                  scroll: false
                });
              }}
            >
              <Text
                color="brandGray.700"
                py={4}
                pb={(showMedia && hasMedia) || !hasInsights ? 4 : 0}
                dangerouslySetInnerHTML={{
                  __html: content
                    ?.replace?.('\r', '<br/>')
                    ?.replace('\n', '<br/>'),
                }}
                fontSize="md"
              />
            </Link>
            ) : (
              <></>
            )}
            {isEvent && (title || excerpt) ? (
              <>
                <EventPostBody fromPublicEvent={fromPublicEvent} />
              </>
            ) : (
              ''
            )}
            {!isEvent && !isAdPost ? (
              <Stack
                w="100%"
                minH={
                  showMedia && hasMedia && !mediaLoaded
                    ? isProfileBannerPost
                      ? '0'
                      : '0px'
                    : '0'
                }
                pos="relative"
              >
                {showMedia ? (
                  <Link
                    // maxH="400px"
                    overflow="hidden"
                    // w="100%"
                    href={`/post/${id}`}
                    onClick={() => {
                      localStorage?.removeItem('commentCLicked', true);
                    }}
                  >
                    <MediaDisplay
                      fromArticle={fromArticle}
                      fromIndex={fromIndex}
                      fromSeperateList={fromSeperateList}
                      onLoaded={() => setMediaLoaded(true)}
                    />
                  </Link>
                ) : (
                  ''
                )}
              </Stack>
            ) : (
              ''
            )}
            <Box ref={targetRef}>
              {isAdPost &&
              (ad_format_detail?.image || ad_format_detail?.video) ? (
                <Stack w="100%" minH="0px" pos="relative">
                  <Link
                    // maxH={'400px'}
                    overflow="hidden"
                    // w="100%"
                    href={post.ad_format_detail.card_destination_url}
                  >
                    <MediaDisplay
                      onLoaded={() => setMediaLoaded(true)}
                      isOnlyPreview={isOnlyPreview}
                    />
                  </Link>
                </Stack>
              ) : (
                ''
              )}
              {isAdPost &&
              ad_format === 'CI' &&
              (ad_format_detail?.description?.length ||
                ad_format_detail?.media?.length) ? (
                <>
                  {ad_format_detail?.description && (
                    <Box py="12px" pr="6px">
                      <Text fontSize="16px" color="#5B5B5B">
                        {ad_format_detail?.description}
                      </Text>
                    </Box>
                  )}
                  <Box maxW={'650px'} overflow="hidden" pl={1} pr={3} mb={3}>
                    <Slider {...settings}>
                      {ad_format_detail?.media?.map((media, i) => (
                        <Card
                          key={Math.random()}
                          border="1px solid #E4E6EB"
                          boxShadow="none"
                          p={1}
                          minW="180px"
                        >
                          <Link
                            onClick={() =>
                              handleAdClicksRouting(
                                'conversion',
                                id,
                                media?.card_destination_url
                              )
                            }
                          >
                            {media?.file ||
                            media?.card_file ||
                            media?.card_file_url ? (
                              <Image
                                src={
                                  media?.file
                                    ? media?.file
                                    : media?.card_file_url &&
                                      media?.card_file_url !== ''
                                    ? media?.card_file_url
                                      ? media?.card_file_url
                                      : URL.createObjectURL(media?.card_file)
                                    : ''
                                }
                                alt={media?.headline}
                                w="100%"
                                h="210px"
                                borderRadius="5px"
                                objectFit="cover"
                                onClick={() =>
                                  !isOnlyPreview
                                    ? handleAdClicksRouting(
                                        'conversion',
                                        id,
                                        media?.card_destination_url
                                      )
                                    : undefined
                                }
                                cursor={!isOnlyPreview ? 'pointer' : 'default'}
                              />
                            ) : (
                              <Square
                                p={2}
                                bg="#D9D9D9"
                                w="100%"
                                h="210px"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <AdCampaignDefaultImageIcon
                                  width="50px"
                                  height="50px"
                                />
                              </Square>
                            )}
                          </Link>
                          <HStack>
                            <Box flex="1" py={3} minh="43px">
                              <Text
                                fontSize="12px"
                                color="#2E2E2A"
                                fontWeight="500"
                              >
                                {media?.card_headline ?? media?.headline}
                              </Text>
                              <Text
                                fontSize="12px"
                                color="#5B5B5B"
                                fontWeight="500"
                              >
                                {media?.description?.length > 22
                                  ? media?.description?.slice(0, 22) + '...'
                                  : media?.description}
                              </Text>
                            </Box>
                            <Button
                              variant="secondary"
                              fontSize="10px"
                              h="34px"
                              minW="73px"
                              borderRadius="20px"
                              fontWeight="500"
                              color="brandGray.900"
                              isDisabled={isOnlyPreview}
                              _disabled={{
                                bg: 'brandGray.500',
                                cursor: 'default',
                              }}
                              p={1}
                              mt={1}
                              onClick={() =>
                                handleAdClicksRouting(
                                  'conversion',
                                  id,
                                  media?.card_destination_url
                                )
                              }
                            >
                              {ad_format_detail?.call_to_action !==
                              'Select Option'
                                ? _.filter(
                                    CALL_TO_ACTION,
                                    (item) =>
                                      item?.value ===
                                      ad_format_detail?.call_to_action
                                  )?.[0]?.label
                                : 'Call to action'}
                            </Button>
                          </HStack>
                        </Card>
                      ))}
                    </Slider>
                  </Box>
                </>
              ) : (
                ''
              )}
            </Box>
            {isArticle && (
              <Box
                w={isMobile&&"96%"}
                mx={isMobile&&"auto"}
                minH="220px"
                my={!post?.cover_image && '20px'}
                mb={!hasInsights && isSearch && '20px'}
                py="12px"
                px="16px"
                mr={fromSeperateList&&fromIndex&&!isMobile?'200px':''}
                bg={isSearch ? '#f7f7f7f7' : '#E4E6EB'}
                border={'1px solid #E4E6EB'}
                style={{
                  borderRadius: !post?.cover_image ? '20px' : '0 0 20px 20px',
                }}
                boxShadow="0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"
              >
                <Box
                  style={
                    isSearch
                      ? { display: 'flex', justifyContent: 'space-between' }
                      : {}
                  }
                >
                  <Box>
                    <Box>
                      <Link href={`/articles/detail/${slug}`}>
                        <Heading
                          fontSize="20px"
                          fontWeight="500"
                          color="#3D5A80"
                        >
                          {title.length > 43
                            ? title?.slice(0, 43) + '...'
                            : title}
                        </Heading>
                      </Link>
                    </Box>
                    <Box>
                      <HStack fontSize="14px" color="#747474" py={2}>
                        <Text>
                          by{' '}
                          {company?.id
                            ? `${company?.name}, ${
                                company?.city
                                  ? company?.city + ', ' + company?.country
                                  : company?.country
                              }`
                            : author?.display_name}{' '}
                        </Text>
                        {readers?.length > 0 ? (
                          <Box>
                            <Box as="span" fontSize="11px">
                              &#9679;
                            </Box>{' '}
                            {readers?.length ? readers?.length : '0'}{' '}
                            {readers?.length > 1 ? 'Readers' : 'Reader'}
                          </Box>
                        ) : (
                          <></>
                        )}
                      </HStack>
                    </Box>
                  </Box>
                  {isSearch && (
                    <ForceSignInWrapper>
                      <Box>
                        {post?.bookmark_user_ids?.includes(
                          userProfileInfo?.id
                        ) || isBookMarked ? (
                          <IconButton
                            variant="secondary"
                            icon={<BookMarkedPostIcon />}
                            onClick={() => handleBlogBookMark(post?.id)}
                          />
                        ) : (
                          <IconButton
                            variant="secondary"
                            icon={<BookMarkPostIcon />}
                            onClick={() => {
                              setIsBookMarked(true);
                              handleBlogBookMark(post?.id);
                            }}
                          />
                        )}
                      </Box>
                    </ForceSignInWrapper>
                  )}
                </Box>

                <Text fontSize="16px" color="#5B5B5B">
                  {excerpt}
                </Text>
              </Box>
            )}
            {isAdPost && ad_format !== 'CI' ? (
              <Box ref={targetRef}>
                {ad_format_detail?.description && (
                  <Box
                    py="12px"
                    px="16px"
                    bg="#E4E6EB"
                    style={{ borderRadius: '0px 0px 8px 8px' }}
                  >
                    <Text fontSize="16px" color="#5B5B5B">
                      {ad_format_detail?.description}
                    </Text>
                  </Box>
                )}

                <HStack justifyContent="center">
                  {!ad_format_detail?.call_to_action ||
                  ad_format_detail?.call_to_action === null ? (
                    <Text p={3}>Call to action</Text>
                  ) : (
                    <Button
                      variant="transparent"
                      isDisabled={isOnlyPreview}
                      _disabled={{
                        bg: 'transparent',
                        cursor: 'default',
                      }}
                      p={3}
                      onClick={() =>
                        handleAdClicksRouting(
                          'conversion',
                          id,
                          ad_format_detail?.card_destination_url
                        )
                      }
                    >
                      {
                        _.filter(
                          CALL_TO_ACTION,
                          (item) =>
                            item?.value === ad_format_detail?.call_to_action
                        )?.[0]?.label
                      }
                    </Button>
                  )}
                </HStack>
              </Box>
            ) : (
              ''
            )}
            <Stack spacing={0}>
              {repost ? (
                <PostBoxContext.Provider value={repostBoxContext}>
                  <Box
                    {...(quoted
                      ? {
                          mx: 4,
                          mt: hasMedia ? 0 : 2,
                          mb: hasInsights ? 0 : 2,
                          overflow: 'hidden',
                          border: '1px solid',
                          borderColor: 'gray.200',
                          borderRadius: 5,
                          pb: 4,
                          ml: 0,
                          bg: '#EFEFEF',
                        }
                      : {})}
                  >
                    {quoted ? (
                      <PostBoxHeader
                        actionMenuEnabled={false}
                        quoted={quoted}
                      />
                    ) : (
                      ''
                    )}
                    <PostBoxBody />
                  </Box>
                </PostBoxContext.Provider>
              ) : (
                ''
              )}
            </Stack>
          </Box>
          {child_posts?.length ? (
            <Box className="threadedPosts">
              {child_posts
                ?.slice(0, !isInFrame ? 3 : child_posts?.length)
                ?.map((childPost, index) => (
                  <Box
                    key={post?.id}
                    display="flex"
                    w="100%"
                    mt="47px"
                    alignItems="flex-start"
                    className="threadPostAvatar"
                  >
                    {/* <Text>{childPost?.id}</Text> */}
                    <Stack p="4px 0px 8px 10px" bg="transperent" h="56px">
                      {isMobile ? (
                        <Avatar
                          as={Link}
                          href={link}
                          size="md"
                          name={
                            company?.name?.length ? company?.name : userFullName
                          }
                          src={
                            company?.image?.length
                              ? company?.image
                              : userProfilePic
                          }
                          loading="lazy"
                        />
                      ) : (
                        <Box w="100%">
                          <>
                            <Avatar
                              as={Link}
                              href={link}
                              size="md"
                              name={
                                company?.name?.length
                                  ? company?.name
                                  : userFullName
                              }
                              src={
                                company?.image?.length
                                  ? company?.image
                                  : userProfilePic
                              }
                              loading="lazy"
                            />
                            {/* <Stack direction='row' h='100%' color="#E4E6EB" px={5} py={2}>
                    <Divider orientation='vertical' style={{ border: "2px solid #E4E6EB" }} />
                  </Stack> */}
                          </>
                        </Box>
                      )}
                    </Stack>
                    {index === 2 && !isInFrame ? (
                      <VStack h="56px" ml={1} justifyContent="center">
                        <Link
                          // href={postModalLink?.(childPost?.parent_post)}
                          href={`/post/${childPost?.parent_post}`}
                          onClick={() => {
                            localStorage?.setItem('commentCLicked', true);
                          }}
                        >
                          See more...
                        </Link>
                      </VStack>
                    ) : (
                      <SinglePostBody
                        isOnlyPreview={isOnlyPreview}
                        post={childPost}
                        postIndex={index}
                      />
                    )}
                  </Box>
                ))}
            </Box>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};
